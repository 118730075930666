import React from "react";
import "./App.scss";
import {
  ABIAppButton,
  AppButtonVariant,
  RoundOrSquare,
} from "@ab-inbev-labs/ux-react-components";
import { useUtilsMediaQuery } from "@ab-inbev/sam-utils";

type AppCardProps = {
  title: string;
  description: string;
  path: string;
  ctaLabel: string;
};

const AppCard = ({ title, description, path, ctaLabel }: AppCardProps) => {
  const handleClick = () => {
    window.location.assign(`${window.location.origin}/${path}`);
  };
  const isMobileViewPort = useUtilsMediaQuery("(max-width: 1000px)");
  return (
    <div
      className={
        isMobileViewPort
          ? "apps-grid-card-mobile apps-grid-card"
          : "apps-grid-card"
      }
    >
      <div className="apps-card-header-wrap" title={title}>
        <div className="apps-card-header-item">{title}</div>
      </div>
      <div className={`${isMobileViewPort ? "app-card-body-mobile" : ""}`}>
        <div
          className={
            isMobileViewPort
              ? "apps-card-description-mobile"
              : "apps-card-description"
          }
        >
          {description}
        </div>
        <div
          className={
            isMobileViewPort ? "apps-card-cta-mobile" : "apps-card-cta"
          }
        >
          <div>
            <ABIAppButton
              variant={AppButtonVariant.Primary}
              corner={RoundOrSquare.Square}
              onClick={handleClick}
            >
              {ctaLabel}
            </ABIAppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCard;
